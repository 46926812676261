import React from "react";
import Typed from "./Typed";
import "./Border.scss"

class Border extends React.Component {

    zeroOne = "⟁111".repeat(100);

    render() {
        return <div className={"border " + this.props.className}>
            <div><Typed
                finished={this.props.finished}
                className={"border-text glitch-text-rare border-text-1"}
                typeSpeed={1}
                title={[
                    "1".repeat(5000) + this.zeroOne.shuffle(),
                ]}
            /></div>
            <div><Typed
                finished={this.props.finished}
                className={"border-text glitch-text-rare border-text-2"}
                typeSpeed={10}
                title={[
                    "⟁".repeat(1000) + this.zeroOne.shuffle(),
                ]}
            /></div>
            <div><Typed
                finished={this.props.finished}
                className={"border-text glitch-text-rare border-text-3"}
                typeSpeed={10}
                title={[
                    "M".repeat(1000) + this.zeroOne.shuffle(),
                ]}
            /></div>
        </div>
    }
}

export default Border;
