import './styles/Home.scss';
import './Exposition.scss';
import React from "react";

class Exposition extends React.Component {
    render() {
        return (<>
            <div className={"exposition-container select-disable"}>
                <div>
                    <div className={"exposition"}>
                        <div className={"exposition-art-container"}>
                            {this.props.logo ? this.logo() : ''}
                            <video autoPlay={true} loop={true} muted={true}>
                                <source src={this.props.img} type="video/webm"/>
                            </video>
                            <div className={"exposition-note"}>{this.props.caption}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }

    logo() {
        return <div className="home-title box box-shadow glitch-text">
            <span className={"home-title-s home-title-s1"}>1</span>
            <span className={"home-title-s home-title-s2"}>⟁</span>
            <span className={"home-title-s home-title-s3"}>M</span>
        </div>;
    }
}

export default Exposition;
