import React from 'react';
import ReactDOM from 'react-dom/client';
import './common/Strings.js'
import './styles/index.scss';
import './styles/Fonts.css';
import './styles/gallery.scss';
import './styles/Text.scss';
import reportWebVitals from './reportWebVitals';
import Gallery from "./Gallery";

ReactDOM.createRoot(document.getElementById("root")).render(
    <div className={"extended-view"} id={'scroll'}>
        <Gallery></Gallery>
    </div>
);

reportWebVitals();
